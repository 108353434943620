<template>
	<div data-route>
		<page-header
			heading="Courses"
		/>
		<div data-element="main">
			<card-view
				:cards="getCards"
				:pagination="pagination"
				:totalItems="totalItems"
				@search="onSearch"
				@paginate="onPaginate"
			/>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isAdminOnly": true
		}
	}
</route>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import CardView    from '@/components/ui/views/cardView/CardView';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Courses'
		},
		components: {
			PageHeader,
			CardView
		},
		data: () => ({
			courses: [],
			searchString: '',
			pagination: {
				perPage: 21,
				currentPage: 1,
				totalPages: 0
			},
			totalItems: 0
		}),
		computed: {
			getCourses () {
				if (!this.courses ||
						!this.courses.length) {
					return false;
				}
				return this.courses;
			},
			getCards () {
				if (!this.getCourses) {
					return [];
				};
				return this.getCourses.map((course) => {
					return {
						route: this.getCourseRoute(course),
						course
					};
				});
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/courses',
						text: 'Courses'
					}
				];
			}
		},
		created: function () {
			this.setCourses();
		},
		methods: {
			onSearch (value) {
				this.searchString = value;
				this.setCourses();
			},
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setCourses();
			},
			getCourseRoute (course) {
				return `/admin/courses/${course.id}`;
			},
			async setCourses () {
				const response = await api.admin.getCourses({
					include:       'metadata',
					searchString:  this.searchString,
					per:           this.pagination.perPage,
					page:          this.pagination.currentPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.totalItems = response.meta.totalItems;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.courses = response.results;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
